import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Front from './screen/Front';
import Post from './screen/Post';
import './App.css';

function App() {
	return (
		<div className=" ">
			<Router>
				<Switch>
					<Route exact path="/" component={Front} />
					<Route path="/post/:id" component={Post} />
				</Switch>
			</Router>
		</div>
	);
}

export default App;
