import { useHistory } from 'react-router';

import Tages from './Tages';
import img from '../static/bg.jpeg';
function Card({ props }) {
	const history = useHistory();
	console.log(props.props);

	return (
		<div
			className={` border-l-8  ${props.border} rounded-xl mx-2 my-4 bg-gray-100  shadow-2xl transform hover:rotate-2 hover:scale-105 transition duration-150 cursor-pointer`}
			style={{ width: '370px', height: 'auto', flex: '1 0 auto', maxWidth: '500px' }}
			onClick={() => history.push('/post/id')}
		>
			<div
				style={{
					width: '100%',
					height: '300px',
					backgroundImage: `url(${img})`
				}}
				className="  rounded-tr-xl relative bg-cover"
			>
				<div className="absolute bottom-2  flex">
					{props.tage && props.tage.map((item) => <Tages props={item} />)}
				</div>
				<div />
			</div>

			<div className="p-1 text-2xl text-gray-700 font-bold font-sans mx-3">{props.title}</div>
			<div className="p-1  text-yellow-700 text-xl  flex justify-between mx-3">
				<span className="">{props.writer}</span>
				<span>16/11/2021</span>
			</div>
			<div className="   text-gray-700">
				<p className="m-3 line-clamp-5 text-gray-900 ">
					{props.snap ? props.snap : <p>Snape is loading.....</p>}
				</p>
			</div>
		</div>
	);
}

export default Card;
