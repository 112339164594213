import React from 'react';

function Footer({ textcolor, footerBg }) {
	return (
		<div
			className={`flex  h-full w-full items-center justify-center space-x-4 ${textcolor}  ${footerBg} text-2xl cursor-pointer`}
		>
			<div>About</div>
			<div>Privacy</div>
			<div>Contact</div>
			<div>All Rights are Reserved</div>
		</div>
	);
}

export default Footer;
