import '../static/bgSvg.css';
function Galary() {
	return (
		<div className="w-full h-96  px-autoflex flex flex-col items-center justify-center mt-32    ">
			<div className=" flex flex-row h-48  w-4/6">
				<div className=" w-2/6 h-full bg-gray-500 shadow-2xl opacity-60 hover:opacity-100 transition-opacity duration-300 cursor-pointer ">
					<img src="http://localhost:3000/bg.jpeg" />
				</div>
				<div className="w-2/6 h-full ">
					<h2 className=" text-center font-bold text-3xl w-auto text-gray-900 text-opacity-75">Check also</h2>
				</div>
				<div className="w-2/6 h-full bg-gray-500 shadow-2xl  opacity-60 hover:opacity-100  transition-opacity  cursor-pointer">
					<img src="http://localhost:3000/bg.jpeg" />
				</div>
			</div>
			<div className=" flex flex-row h-48 w-4/6 ">
				<div className=" w-2/6 h-full " />
				<div className="w-2/6 h-full bg-gray-500 shadow-2xl  opacity-60 hover:opacity-100 transition-opacity  cursor-pointer">
					<img src="http://localhost:3000/bg.jpeg" />
				</div>
				<div className="w-2/6 h-full  " />
			</div>
		</div>
	);
}

export default Galary;
