import { Link } from 'react-router-dom';
function Header() {
	return (
		<div className="flex  h-full w-full justify-end space-x-4 text-yellow-400 text-2xl cursor-pointer">
			<div>
				<Link to="/">Main</Link>
			</div>
			<div>About</div>
			<div>Contact</div>
		</div>
	);
}

export default Header;
