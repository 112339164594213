import { useParams, useHistory } from 'react-router';
import { data } from '../static/postData';
import '../static/bgSvg.css';
import Header from '../componants/Header';
import Footer from '../componants/Footer';

//--------------------color------------
const colorPost = {
	title: 'text-gray-700',
	titleBg: 'bg-green-600',
	written: 'text-gray-700',
	name: 'text-green-900',
	date: 'text-green-900',
	bg: '',

	footerText: 'text-white'
};
//---------test---parser---

export default function Post() {
	const params = useParams();
	const loc = useHistory();
	console.log(loc.location.pathname);

	return (
		<div className="w-full h-full ">
			<div>
				<Header />
			</div>
			<div className={`flex flex-col ${colorPost.titleBg}`}>
				<div className="flex flex-row pt-32 ">
					<div className="w-1/2 h-3/6   ">
						<img className=" ml-9  shadow-xl hidden  xl:block " src={data.img} alt="photo" />
					</div>
					<div className="w-1/2 m-9  xl:w-full h-1/4 max-w-2xl  ">
						<h1 className={`text-5xl font-bold pt-48  px-6 ${colorPost.title}`}>{data.title}</h1>
						<p className={`text-1xl font-bold pt-16 px-3 ${colorPost.written}`}>
							Writte By <span className={`font-extralight ${colorPost.name}`}>{data.name}</span> On
							<span className={`${colorPost.name}`}> {data.date}</span>
						</p>
					</div>
				</div>
			</div>
			<div>
				<div className="px-96 h-auto transform -translate-y-28 ">
					<div className=" max-w-7xl bg-white rounded-2xl pl-28">
						<div dangerouslySetInnerHTML={{ __html: data.p }} />
					</div>
				</div>
				<div />
			</div>
			<div>
				<Footer textcolor={colorPost.footerText} footerBg={colorPost.titleBg} />
			</div>
		</div>
	);
}
