export const data = {
	title: 'My first page ',
	bio:
		' As you may know iam back as the most  as you may know iam back as the mostas you may know iam back as the most',
	card: [
		{
			id: '1245',
			title: 'svg world is here ',
			snap:
				'SVG filters are the bomb 💣, if I say so myself! They allow to apply visual effects like drop shadows, gaussian blurs, color manipulation or even distortion on any HTML or SVG element on your page. You read that right, SVG filters are not just for SVGs, they are defined in SVG markup, but can then be applied to any element, as you’ll see here.',
			time: '16/11/1958',
			writer: 'ali',
			tage: [ { body: 'ssa', bg: 'bg-red-100' }, {}, { body: 'ssa', bg: 'bg-red-100' } ],
			border: 'border-red-400'
		},
		{
			id: '1245',
			title: 'svg world is here ',
			snap:
				'SVG filters are the bomb 💣, if I say so myself! They allow to apply visual effects like drop shadows, gaussian blurs, color manipulation or even distortion on any HTML or SVG element on your page. You read that right, SVG filters are not just for SVGs, they are defined in SVG markup, but can then be applied to any element, as you’ll see here.',
			time: '16/11/1958',
			writer: 'ali',
			tage: [ { body: 'ssa', bg: 'bg-red-100' }, {}, { body: 'ssa', bg: 'bg-red-100' } ],
			border: 'border-green-400'
		},
		{
			id: '1245',
			title: 'svg world is here ',
			snap:
				'SVG filters are the bomb 💣, if I say so myself! They allow to apply visual effects like drop shadows, gaussian blurs, color manipulation or even distortion on any HTML or SVG element on your page. You read that right, SVG filters are not just for SVGs, they are defined in SVG markup, but can then be applied to any element, as you’ll see here.',
			time: '16/11/1958',
			writer: 'ali',
			tage: [ { body: 'ssa', bg: 'bg-red-100' }, {}, { body: 'ssa', bg: 'bg-red-100' } ]
		},
		{
			id: '1245',
			title: 'svg world is here ',
			snap:
				'SVG filters are the bomb 💣, if I say so myself! They allow to apply visual effects like drop shadows, gaussian blurs, color manipulation or even distortion on any HTML or SVG element on your page. You read that right, SVG filters are not just for SVGs, they are defined in SVG markup, but can then be applied to any element, as you’ll see here.',
			time: '16/11/1958',
			writer: 'ali',
			tage: [ { body: 'ssa', bg: 'bg-red-100' }, {}, { body: 'ssa', bg: 'bg-red-100' } ]
		},
		{
			id: '1245',
			title: 'svg world is here ',
			snap:
				'SVG filters are the bomb 💣, if I say so myself! They allow to apply visual effects like drop shadows, gaussian blurs, color manipulation or even distortion on any HTML or SVG element on your page. You read that right, SVG filters are not just for SVGs, they are defined in SVG markup, but can then be applied to any element, as you’ll see here.',
			time: '16/11/1958',
			writer: 'ali',
			tage: [ { body: 'ssa', bg: 'bg-red-100' }, {}, { body: 'ssa', bg: 'bg-red-100' } ]
		},
		{
			id: '1245',
			title: 'svg world is here ',
			snap:
				'SVG filters are the bomb 💣, if I say so myself! They allow to apply visual effects like drop shadows, gaussian blurs, color manipulation or even distortion on any HTML or SVG element on your page. You read that right, SVG filters are not just for SVGs, they are defined in SVG markup, but can then be applied to any element, as you’ll see here.',
			time: '16/11/1958',
			writer: 'ali',
			tage: [ { body: 'ssa', bg: 'bg-red-100' }, {}, { body: 'ssa', bg: 'bg-red-100' } ]
		},
		{
			id: '1245',
			title: 'svg world is here ',
			snap:
				'SVG filters are the bomb 💣, if I say so myself! They allow to apply visual effects like drop shadows, gaussian blurs, color manipulation or even distortion on any HTML or SVG element on your page. You read that right, SVG filters are not just for SVGs, they are defined in SVG markup, but can then be applied to any element, as you’ll see here.',
			time: '16/11/1958',
			writer: 'ali',
			tage: [ 'svg', 'bg' ]
		},
		{
			id: '1245',
			title: 'svg world is here ',
			snap:
				'SVG filters are the bomb 💣, if I say so myself! They allow to apply visual effects like drop shadows, gaussian blurs, color manipulation or even distortion on any HTML or SVG element on your page. You read that right, SVG filters are not just for SVGs, they are defined in SVG markup, but can then be applied to any element, as you’ll see here.',
			time: '16/11/1958',
			writer: 'ali',
			tage: [ 'svg', 'bg' ]
		},
		{
			id: '1245',
			title: 'svg world is here ',
			snap:
				'SVG filters are the bomb 💣, if I say so myself! They allow to apply visual effects like drop shadows, gaussian blurs, color manipulation or even distortion on any HTML or SVG element on your page. You read that right, SVG filters are not just for SVGs, they are defined in SVG markup, but can then be applied to any element, as you’ll see here.',
			time: '16/11/1958',
			writer: 'ali',
			tage: [ 'svg', 'bg' ]
		}
	]
};
