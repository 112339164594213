import Card from '../componants/Card';
import Galary from '../componants/Galary';
import { data } from '../static/data';
import '../static/bgSvg.css';
import Footer from '../componants/Footer';
import Header from '../componants/Header';
import svg from '../static/3.svg';

//------------COLOR ------
const color = {
	bg: '',
	title: 'text-yellow-300',
	bio: 'text-yellow-300',
	search: 'text-gray-800  ',
	footerBg: 'bg-yellow-300 ',
	footerText: 'text-white'
};

function Front() {
	return (
		<div
			className={` h-full ${color.bg} flex flex-col    `}
			style={{
				backgroundImage: `url(${svg})`,
				backgroundSize: 'cover'
			}}
		>
			<Header />
			{data.length !== 0 ? (
				<div>
					<div>
						<div>
							<h1
								className={`text-center ${color.title} text-5xl  mt-10 color transform duration-150 hover:scale-110 `}
							>
								{data.title}
							</h1>
						</div>
						<div>
							<h2
								className={`${color.bio} mx-auto text-center text-2xl font-bold font-serif max-w-2xl m-16`}
							>
								{data.bio}
							</h2>
						</div>
					</div>
					<div>
						<div className="flex justify-center items-center">
							<input
								className={`h-12  ring-2 ring-yellow-400  text-yellow-400 text-2xl rounded-xl p-1 focus:outline-none ${color.search} `}
								placeholder="Search"
								style={{ width: '500px', backgroundColor: '#222831' }}
							/>
						</div>
						<div className=" flex  flex-row flex-wrap justify-center sm:px-10 md:px-80 py-10 ">
							{data.card.map((data) => <Card props={data} />)}
						</div>
					</div>
				</div>
			) : (
				'Ss'
			)}
			<div className="flex  flex-col justify-center it">
				<div className="h-full">
					<Galary />
				</div>
				<div className="h-20 w-full mt-60 ">
					<Footer textcolor={color.footerText} footerBg={color.footerBg} />
				</div>
			</div>
		</div>
	);
}

export default Front;
